.mission-container {
  flex-direction: column;
  color: white;
  min-height: 100vh;
  position: relative;
  background-color: #009ffd;
}

.mission {
  display: flex;
  justify-content: right;
}

.mission .title {
  margin-top: 150px;
  margin-right: 350px;
}

.mission-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
}

.mission-text {
  font-size: 20px;
  width: 600px;
  text-align: justify;
}

.values-title {
  font-size: 24px;
  font-weight: 500;
}

.values {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.values .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 40px;
  width: 30px;
  height: 30px;
}

.values .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 170px;
  margin-top: 20px;
}

.values-names {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.values img {
  filter: invert(43%) sepia(30%) saturate(5090%) hue-rotate(180deg) brightness(106%) contrast(102%);
  width: 60px;
}

@media screen and (max-width: 1370px) {
  .values-title {
    font-size: 20px;
  }

  .values {
    width: 450px;
  }

  .values .wrap {
    width: 10px;
    height: 10px;
  }

  .values .icon {
    min-width: 140px;
  }

  .values img {
    width: 40px;
    height: 40px;
  }

  .mission-content {
    margin-top: 40px;
  }

  .mission-text {
    font-size: 18px;
    width: 500px;
  }

  .mission .title h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .values-title {
    font-size: 18px;
  }

  /* .values {
    width: 420px;
  } */

  .values .wrap {
    width: 5px;
    height: 5px;
  }

  .mission-content {
    flex-direction: column;
  }

  .mission-container .content-first {
    display: flex;
    flex-direction: column;
    order: 1;
  }

  .mission-container .content-second {
    display: flex;
    justify-content: center;
    order: 0;
  }

  .mission-text {
    width: 80%;
  }

  .mission .title h1 {
    font-size: 24px;
  }

  .mission {
    justify-content: center;
  }

  .mission .title {
    margin-top: 200px;
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .mission .title h1 {
    font-size: 20px;
  }
  
  .mission .title {
    margin-top: 100px;
  }

  .mission-text {
    font-size: 16px;
  }

  .values {
    width: 380px;
  }

  .values .wrap {
    width: 2px;
    height: 2px;
  }

  .values img {
    width: 30px;
    height: 30px;
  }

  .values .icon {
    min-width: 120px;
  }

  .values-names {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .mission .title h1 {
    font-size: 18px;
  }

  .values {
    width: 250px;
  }

  .values-names {
    font-size: 14px;
  }
}

@media screen and (max-width: 330px) {
  .mission .title h1 {
    font-size: 16px;
  }

  .mission-text {
    font-size: 14px;
  }

  .values-title {
    font-size: 16px;
  }

  .values-names {
    font-size: 12px;
  }

  .values .wrap {
    padding: 30px;
  }
}
