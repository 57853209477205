html {
  scroll-behavior: smooth;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #2a2a72;
  min-height: 100vh;
}

.cloud, .pc, .cubes {
  width: 800px;
}

.cloud-pc {
  display: grid;
  position: relative;
}

.cloud-pc .pc, .cubes {
  position: absolute;
  animation: slide 5s ease-in-out infinite;
  z-index: 1;
}

.footer {
  background-color: #005fab;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  height: 50px;
}

.footer .name-link {
  font-weight: 500;
  color: white;
  text-decoration: underline;
}

#shadow-1 {
  position: absolute;
  bottom: 75px;
  left: 42px;
  animation: shadow 5s ease-in-out infinite;
}

#shadow-2 {
  position: absolute;
  bottom: 30px;
  left: 230px;
  animation: shadow 5s ease-in-out infinite;
}

#shadow-3 {
  position: absolute;
  top: 100px;
  right: 40px;
  animation: shadow 5s ease-in-out infinite;
}

@keyframes slide {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes shadow {
  0% {
    -webkit-transform: scale(100%);
            transform: scale(100%);
  }
  50% {
    -webkit-transform: scale(90%);
            transform: scale(90%);
  }
  100% {
    -webkit-transform: scale(100%);
            transform: scale(100%);
  }
}

@media screen and (max-width: 1370px) {
  .cloud, .pc, .cubes {
    width: 600px;
  }

  #shadow-1 {
    width: 43px;
    bottom: 58px;
    left: 32px;
  }

  #shadow-2 {
    width: 43px;
    bottom: 25px;
    left: 174px;
  }

  #shadow-3 {
    width: 43px;
    top: 75px;
    right: 32px;
  }
}

@media screen  and (max-height: 590px) {
  .home {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .page-container {
    flex-direction: column;
  }

  .home-title {
    margin-top: 120px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    height: 80px;
  }

  .cloud, .pc, .cubes {
    width: 400px;
  }

  #shadow-1 {
    width: 28px;
    bottom: 40px;
    left: 21px;
  }

  #shadow-2 {
    width: 28px;
    bottom: 18px;
    left: 116px;
  }

  #shadow-3 {
    width: 28px;
    top: 49px;
    right: 22px;
  }
}

@media screen and (max-width: 450px) {
  .footer {
    flex-direction: column;
    height: 80px;
  }

  .cloud, .pc, .cubes {
    width: 350px;
  }

  #shadow-1 {
    width: 28px;
    bottom: 35px;
    left: 17px;
  }

  #shadow-2 {
    width: 28px;
    bottom: 16px;
    left: 100px;
  }

  #shadow-3 {
    width: 28px;
    top: 40px;
    right: 17px;
  }
}

@media screen and (max-width: 450px) {
  .footer span {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .cloud, .pc, .cubes {
    width: 280px;
  }

  #shadow-1 {
    width: 22px;
    bottom: 29px;
    left: 14px;
  }

  #shadow-2 {
    width: 22px;
    bottom: 13px;
    left: 80px;
  }

  #shadow-3 {
    width: 22px;
    top: 32px;
    right: 14px;
  }

  .footer span {
    font-size: 14px;
  }
}