.page-container {
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  color: white;
  position: relative;
}

.home {
  align-items: center;
  background-image: linear-gradient(#2a2a72 0%, #009ffd 74%);
}

.home-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease;
  color: white;
  width: 500px;
  text-transform: uppercase;
  font-size: 20px;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.home-btn {
  align-items: center;
  display: flex;
  border: 2px solid transparent;
  padding: 12px;
  justify-content: space-around;
  width: 55%;
  cursor: pointer;
  transition: all 0.4s ease;
}

.btn-icon {
  transition: all 0.4s ease;
}

.home-btn:hover {
  border: 2px solid white;
}

.home-btn:hover .btn-icon {
  transition: all 0.4s ease;
  transform: rotateZ(90deg);
}

.home-title span {
  font-size: 18px;
}

.title {
  font-size: 24px;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  text-transform: uppercase;
  margin-top: 5%;
  z-index: 2;
}

.title h1 {
  border-bottom: 3px solid white;
  font-weight: 500;
}

.shape {
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 1370px) {
  .home-title {
    font-size: 18px;
  }

  .home-btn {
    width: 48%;
    font-size: 16px;
  }

  .home-title span {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .home-title {
    font-size: 16px;
    width: 400px;
  }

  .home-btn {
    width: 52%;
    font-size: 14px;
  }

  .home-title span {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .home-title {
    font-size: 12px;
    width: 300px;
  }

  .home-btn {
    width: 60%;
    font-size: 10px;
  }

  .home-title span {
    font-size: 12px;
  }
}

@media screen and (max-width: 330px) {
  .home-title {
    font-size: 10px;
    width: 80%;
  }

  .home-btn {
    width: 48%;
    font-size: 8px;
  }

  .home-title span {
    font-size: 10px;
  }
}
