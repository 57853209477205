.about-container {
  flex-direction: column;
  color: white;
  min-height: 100vh;
  position: relative;
  background-color: #0071cc;
}

.about {
  display: flex;
  justify-content: left;
}

.about .title {
  margin-top: 150px;
  margin-left: 250px;
}

.about-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
}

.about-text {
  font-size: 20px;
  width: 600px;
  text-align: justify;
}

.services-title {
  font-size: 24px;
  font-weight: 500;
}

.services {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  justify-content: center;
}

.services .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 40px;
  max-width: 30px;
  height: 30px;
}

.services .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 170px;
  margin-top: 20px;
}

.services img {
  filter: invert(31%) sepia(99%) saturate(5386%) hue-rotate(193deg) brightness(97%) contrast(103%);
  width: 60px;
  height: 60px;
}

.services-names {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.about-content .services-more {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1370px) {
  .services-title {
    font-size: 20px;
  }

  .services {
    width: 450px;
  }

  .services .wrap {
    width: 10px;
    height: 10px;
  }

  .services .icon {
    min-width: 140px;
  }

  .services img {
    width: 40px;
    height: 40px;
  }

  .about-content {
    margin-top: 40px;
  }

  .about-text {
    font-size: 18px;
    width: 500px;
  }

  .about .title h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .services-title {
    font-size: 18px;
  }

  .services {
    width: 420px;
  }

  .services .wrap {
    width: 5px;
    height: 5px;
  }

  .about-content {
    flex-direction: column;
  }

  .about-container .content-first {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .about-text {
    width: 80%;
  }

  .about .title h1 {
    font-size: 24px;
  }

  .about {
    justify-content: center;
  }

  .about .title {
    margin-top: 200px;
    margin-left: 0;
  }
}

@media screen and (max-width: 790px) {
  .about {
    justify-content: center;
  }

  .about .title {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .about .title h1 {
    font-size: 20px;
  }

  .about .title {
    margin-top: 100px;
  }

  .about-text {
    font-size: 16px;
  }

  .services {
    width: 450px;
  }

  .services .wrap {
    width: 2px;
    height: 2px;
  }

  .services img {
    width: 30px;
    height: 30px;
  }

  .services .icon {
    min-width: 120px;
  }

  .services-names {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .about .title h1 {
    font-size: 18px;
  }

  .services {
    width: 300px;
  }

  .services-names {
    font-size: 14px;
  }
}

@media screen and (max-width: 330px) {
  .about .title h1 {
    font-size: 16px;
  }

  .about-text {
    font-size: 14px;
  }

  .services-title {
    font-size: 16px;
  }

  .services-names {
    font-size: 12px;
  }

  .services .wrap {
    padding: 30px;
  }
}
