.header {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 3;
}

.box-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-logo span {
  font-size: 20px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.logo {
  width: 50px;
  padding: 10px;
}

.nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  list-style-type: none;
  text-transform: uppercase;
  padding: 0;
}

.nav-item {
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  color: white;
  cursor: pointer;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
  border-bottom: 2px solid transparent;
}

.nav-item:after {
  content: '';
  position: absolute;
  display: block;
  height: 0.1em;
  width: 0%;
  background-color: white;
  transition: all ease-in-out 250ms;
}

.nav-item:hover::after {
  width: 100%;
}

.underlined {
  border-bottom: 2px solid white;
}

.burger-icon {
  color: white;
  font-size: 32px;
}

.mobile {
  flex-direction: column;
}

.nav-mobile {
  display: none;
}

@media screen and (max-width: 1200px) {
  .logo {
    width: 40px;
  }

  .box-logo span {
    font-size: 18px;
  }

  .nav-item {
    font-size: 18px;
  }

  .nav-item:after {
    display: none;
  }
}

@media screen and (max-width: 790px) {
  .logo {
    width: 30px;
  }

  .box-logo span {
    font-size: 16px;
  }

  .nav, .nav-list {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .nav-item {
    font-size: 12px;
  }
}