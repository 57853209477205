.contact {
  display: flex;
  justify-content: left;
}

.contact .title {
  margin-top: 150px;
  margin-left: 250px;
}

.contact-container {
  flex-direction: column;
  color: white;
  min-height: 90vh;
  position: relative;
  background-color: #0071cc;
}

.contact-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 30px;
  width: 500px;
  height: 420px;
}

.contact-form input {
  padding: 8px;
  height: 30px;
}

.contact-form textarea {
  padding: 8px;
  height: 130px;
  box-sizing: border-box;
  resize: none;
  width: 100%;
}

input, textarea {
  background-color: #005fab;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
  transition: all 0.4s ease;
}

.input-default {
  border-bottom: 2px solid #009ffd;
}

.error {
  border-bottom: 2px solid rgba(255, 0, 0, 0.8);
}

input::placeholder, textarea::placeholder {
  color: white;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.contact-form .upper-line {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.contact-form button {
  display: flex;
  background-color: #005fab;
  border: 2px solid #009ffd;
  padding: 10px;
  font-size: 18px;
  color: white;
  width: 130px;
  cursor: pointer;
  transition: all 0.4s ease;
  justify-content: center;
  min-height: 45px;
  align-items: center;
}

.contact-form button:disabled {
  opacity: 1;
  pointer-events: none;
}

.contact-form button:hover {
  background-color: #009ffd;
}

.contact-form .form-text {
  font-size: 20px;
  width: 550px;
}

.contact-form .loading {
  animation: spin 1s linear infinite;
}

.contact-form .error-text {
  font-size: 18px;
  transition: all 0.4s ease;
}

.contact-form .none-error {
  font-size: 18px;
  min-height: 24px;
  color: transparent;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-title {
  font-weight: 400;
}

.social-media {
  font-size: 30px;
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trace {
  height: 1px;
  width: 50px;
  background-color: white;
}

.social-media a {
  color: white;
  text-decoration: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1370px) {
  .contact-form .form-text {
    font-size: 18px;
  }

  .contact-form {
    width: 450px;
  }

  .upper-line .name {
    width: 43%;
  }

  .upper-line .email {
    width: 43%;
  }

  .contact .title h1 {
    font-size: 28px;
  }

  .contact-title {
    font-size: 20px;
  }

  .social-media {
    font-size: 25px;
    width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-form .form-text {
    font-size: 16px;
    width: 100%;
  }
  
  .contact-form {
    height: 400px;
    margin-top: 0;
  }

  .contact-content {
    flex-direction: column;
  }

  .contact .title h1 {
    font-size: 24px;
  }

  .contact-form .error-text {
    font-size: 16px;
  }

  .contact-form textarea {
    height: 100px;
  }

  .contact-info {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .social-media {
    font-size: 20px;
    width: 280px;
  }

  .contact {
    justify-content: center;
  }

  .contact .title {
    margin-top: 200px;
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .contact-form {
    width: 80%;
  }

  .contact .title {
    margin-top: 100px;
  }
  
  .contact .title h1 {
    font-size: 20px;
  }

  input::placeholder, textarea::placeholder, input {
    font-size: 14px;
  }

  .contact-form button {
    font-size: 14px;
    width: 100px;
  }
}

@media screen and (max-width: 450px) {
  .contact .title h1 {
    font-size: 18px;
  }
}

@media screen and (max-width: 330px) {
  .contact-form .form-text {
    font-size: 14px;
  }

  .social-media {
    font-size: 16px;
    width: 200px;
  }

  .contact-title {
    font-size: 16px;
  }

  .upper-line .name {
    width: 40%;
  }

  .upper-line .email {
    width: 40%;
  }
}
